import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { AiOutlineYoutube } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full footer-bg">
      <div className="pt-[6%]">
        <div className=" container mx-auto flex flex-col gap-[30px]">
          <div className="icons flex items-center justify-center lg:justify-end gap-[9px]">
            <Link className="w-[30px] h-[30px] rounded-full text-white sm:text-black bg-purple sm:bg-white flex items-center justify-center">
              <FaFacebookF />
            </Link>
            <Link className="w-[30px] h-[30px] rounded-full text-white sm:text-black bg-purple sm:bg-white flex items-center justify-center">
              <FaInstagram />
            </Link>
            <Link className="w-[30px] h-[30px] rounded-full text-white sm:text-black bg-purple sm:bg-white flex items-center justify-center">
              <FaXTwitter />
            </Link>
            <Link className="w-[30px] h-[30px] rounded-full text-white sm:text-black bg-purple sm:bg-white flex items-center justify-center">
              <AiOutlineYoutube />
            </Link>
          </div>
          <footer class="border-t-[1px] border-b-[1px] border-white">
            <div class="mx-auto w-full max-w-screen-xl">
              <div class="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
                <div>
                  <h2 class="mb-6 text-md md:text-lg font-semibold  uppercase text-white">
                    {t("AboutHeader")}
                  </h2>
                  <ul class="text-white">
                    <li class="mb-4">
                      <h1 class="">{t("AboutFooter")}</h1>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 class="mb-6 text-md md:text-lg font-semibold text-white uppercase">
                    {t("Contact Information")}
                  </h2>
                  <ul class="text-white">
                    <li class="mb-4">
                      <p class="">
                        {t("the address")}: <br /> {t("Palestine")}
                      </p>
                    </li>
                    <li class="mb-4">
                      <p class="">
                        {t("Email")}: <br /> expmple@test.com
                      </p>
                    </li>
                    <li class="mb-4">
                      <p class="">
                        {t("Phone number")}: <br /> +972 599968870
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 class="mb-6 text-md md:text-lg font-semibold uppercase text-white">
                    {t("Servicesheader")}
                  </h2>
                  <ul class="text-white">
                    <li class="mb-4">
                      <p class="">- {t("Podcast production")}</p>
                    </li>
                    <li class="mb-4">
                      <p class="">- {t("Scenario preparation")}</p>
                    </li>
                    <li class="mb-4">
                      <p class="">- {t("Technical and technical support")}</p>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 class="mb-6 text-md md:text-lg font-semibold  uppercase text-white">
                    {t("Important links")}
                  </h2>
                  <ul class="text-white">
                    <li class="mb-4">
                      <Link to={"/about"} class="hover:underline">
                        - {t("About")}
                      </Link>
                    </li>
                    <li class="mb-4">
                      <Link to={"/library"} class="hover:underline">
                        - {t("Articles")}
                      </Link>
                    </li>
                    <li class="mb-4">
                      <Link to={"/blogs"} class="hover:underline">
                        - {t("Blog")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
          <div className=" w-full flex items-center justify-center">
            <h1 className="text-white text-xl pb-[15px]">
              جميع الحقوق النشر محفوظة - 2024
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
