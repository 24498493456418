import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { Link } from "react-router-dom";
import Title from "../../../components/Title";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

const FaqSection = memo(({ faqSectionData }) => {
  const lng = useSelector((state) => state.lang.value);
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    AOS.init();
  }, []);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <div className=" container mx-auto">
      <div className="flex flex-col gap-[40px] py-[50px]">
        <div className="flex items-center justify-between">
          <Title
            title={
              lng === "ar"
                ? faqSectionData.section_name
                : faqSectionData.section_name_en
            }
          />
          <Link
            to={"/faqspage"}
            className="text-viewAll w-[112px] sm:w-auto underline text-purple"
          >
            {t("View all")}
          </Link>
        </div>
        <div className="w-full transition-faq">
          {faqSectionData.records.map((item, i) => (
            <div
              key={i}
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="1000"
              className="mb-[20px] transition-faq"
            >
              <div
                onClick={() => toggle(i)}
                className={`${
                  selected === i ? "text-white bg-purple" : "text-purple"
                } transition-faq transition-all duration-700 ease-in-out text-[15px] sm:text-lg md:text-xl border-[1px] border-purpleLight rounded-lg py-[25px] px-[35px] cursor-pointer flex items-center justify-between`}
              >
                <h2>{item.title}</h2>
                <span>
                  {selected === i ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
                </span>
              </div>

              {/* Smooth Transition for the Paragraph */}
              <div
                className={`transition-all duration-1000 border-b-[2px] border-l-[2px] border-r-[2px] rounded-lg ease-in-out overflow-hidden ${
                  selected === i
                    ? "max-h-[500px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                <div className="text-gray pt-[25px] pb-[35px] px-[35px] bg-white sh">
                  {item.paragraph}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default FaqSection;
